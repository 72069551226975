<template>
    <div>
        <div>
            <v-snackbar
                app
                v-model="snackbarRight.show"
                :color="snackbarRight.color"
                :timeout="snackbarRight.timeout"
                bottom
                right
            >
                {{ snackbarRight.text }}
                <v-icon v-if="snackbarRight.icon">{{
                    snackbarRight.icon
                }}</v-icon>
                <v-btn
                    icon
                    v-if="snackbarRight.timeout === -1"
                    @click="snackbarRight.show = false"
                    class="snackbar-close"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-snackbar>
            <v-snackbar
                v-if="
                    ($settings.showLicenseLimitApproach &&
                        $session.licenseStatus === 'license-limit-approach') ||
                    ($settings.showLicenseLimitReached &&
                        $session.licenseStatus === 'license-limit-reached')
                "
                app
                v-model="snackbarLeft.show"
                :color="snackbarLeft.color"
                :timeout="snackbarLeft.timeout"
                bottom
                left
            >
                <template
                    v-if="$session.licenseStatus === 'license-limit-approach'"
                >
                    U heeft 90% van het project limiet van uw licentie berijkt
                    <router-link
                        v-if="$session.roles.indexOf('account_admin') !== -1"
                        :to="{
                            name: 'settingsOverviewCategory',
                            params: { category: 'administration' },
                        }"
                        >Klik hier om uw licentie te bekijken.</router-link
                    >
                </template>
                <template
                    v-else-if="
                        $session.licenseStatus === 'license-limit-reached'
                    "
                >
                    U heeft het maximale project limiet bereikt van uw licentie.
                    <br />
                    <router-link
                        v-if="$session.roles.indexOf('account_admin') !== -1"
                        :to="{
                            name: 'settingsOverviewCategory',
                            params: { category: 'administration' },
                        }"
                        >Klik hier om uw licentie te bekijken.</router-link
                    >
                    <template v-else>
                        Neem contact op met uw account administrator
                    </template>
                </template>
                <v-icon v-if="snackbarLeft.icon">{{
                    snackbarLeft.icon
                }}</v-icon>
                <v-btn
                    icon
                    v-if="snackbarLeft.timeout === -1"
                    @click="snackbarLeft.show = false"
                    class="snackbar-close"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-snackbar>
            <v-snackbar
                v-if="
                    $session.isTenant &&
                    ($session.licensePaymentStatus ===
                        'Licenses.error.unpaidLicense' ||
                        $session.licensePaymentStatus ===
                            'Licenses.error.unpaidLicenseGrace' ||
                        $session.licensePaymentStatus ===
                            'Licenses.error.noActiveLicense')
                "
                app
                v-model="snackbarLeft2.show"
                :color="snackbarLeft2.color"
                :timeout="snackbarLeft2.timeout"
                bottom
                left
            >
                <template
                    v-if="
                        $session.licensePaymentStatus ===
                        'Licenses.error.unpaidLicense'
                    "
                >
                    {{ $localize('Licenses.error.unpaidLicense.text') }}
                    <router-link
                        v-if="$session.roles.indexOf('account_admin') !== -1"
                        :to="{
                            name: 'settingsOverviewCategory',
                            params: { category: 'administration' },
                        }"
                        >Klik hier om uw facturen te bekijken.</router-link
                    >
                    <v-btn
                        icon
                        v-if="snackbarLeft2.timeout === -1"
                        @click="snackbarLeft2.show = false"
                        class="snackbar-close"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <template
                    v-else-if="
                        $session.licensePaymentStatus ===
                        'Licenses.error.unpaidLicenseGrace'
                    "
                >
                    {{ $localize('Licenses.error.unpaidLicenseGrace.text') }}
                    <router-link
                        v-if="$session.roles.indexOf('account_admin') !== -1"
                        :to="{
                            name: 'settingsOverviewCategory',
                            params: { category: 'administration' },
                        }"
                        >Klik hier om uw facturen te bekijken.</router-link
                    >
                    <v-btn
                        icon
                        v-if="snackbarLeft2.timeout === -1"
                        @click="snackbarLeft2.show = false"
                        class="snackbar-close"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <template
                    v-else-if="
                        $session.licensePaymentStatus ===
                        'Licenses.error.noActiveLicense'
                    "
                >
                    {{ $localize('Licenses.error.noActiveLicense.text') }}
                    <router-link
                        v-if="$session.roles.indexOf('account_admin') !== -1"
                        :to="{
                            name: 'settingsOverviewCategory',
                            params: { category: 'administration' },
                        }"
                        >Klik hier om uw licentie te beheren.</router-link
                    >
                    <v-btn
                        icon
                        v-if="snackbarLeft2.timeout === -1"
                        @click="snackbarLeft2.show = false"
                        class="snackbar-close"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <v-bottom-sheet :value="showError" persistent>
            <v-card>
                <v-card-title class="error--text">{{
                    error.data.message
                }}</v-card-title>
                <v-card-title>{{ error.data.className }}</v-card-title>
                <v-card-text>
                    <div class="pre">{{ error.data.stacktrace }}</div>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-show="errors.length === 0" @click="closeError()"
                        >close</v-btn
                    >
                    <v-btn v-show="errors.length > 0" @click="closeError()"
                        >close ({{ errors.length }} more)</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>

<script>
import PubSub from 'pubsub-js'
import { subscribe, unsubscribe } from '@/services/websocketHandler'

export default {
    data() {
        return {
            snackbarRight: { show: false },
            snackbarLeft: {
                show: this.$session.licenseStatus !== 'ok',
                color: 'warning',
                timeout: -1,
                text: 'U heeft 90% van uw licentie berijkt',
            },
            snackbarLeft2: {
                show: this.$session.licensePaymentStatus !== 'ok',
                color: 'warning',
                timeout: -1,
            },

            showError: false,
            error: {
                data: {},
            },
            errors: [],
            token1: null,
            token2: null,
            token3: null,
            subscription1: null,
        }
    },
    created() {
        this.token1 = PubSub.subscribe('showSnackbar', this.onShowSnackbar)
        this.token2 = PubSub.subscribe('showError', this.onShowError)
        this.token3 = PubSub.subscribe('clearSnackbar', this.clearSnackbar)
        this.subscription1 = subscribe('ErrorMessage', this.onWebSocketMessage)
    },
    destroyed() {
        PubSub.unsubscribe(this.token1)
        PubSub.unsubscribe(this.token2)
        PubSub.unsubscribe(this.token3)
        unsubscribe(this.subscription1)
    },
    methods: {
        async onShowSnackbar(message, options) {
            this.snackbarRight = options
        },
        async onShowError(message, options) {
            if (!this.showError) {
                this.error = options
                this.showError = true
            } else {
                this.errors.push(options)
            }
        },
        async clearSnackbar() {
            this.snackbarRight.show = false
        },
        closeError() {
            if (this.errors.length === 0) {
                this.showError = false
            } else {
                this.error = this.errors.splice(0, 1)[0]
            }
        },
        onWebSocketMessage(message) {
            this.onShowSnackbar('', {
                show: true,
                text: message.message,
                timeout: -1,
                color: message.success ? 'success' : 'error',
            })
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .v-snack__content {
    font-size: 14px;
}
.snackbar-close {
    position: absolute;
    right: 0;
    top: 0;
}
</style>

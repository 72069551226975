<template>
    <div>
        <v-text-field
            filled
            :label="label"
            :hint="hint"
            :persistent-hint="true"
            :value="inputPreview"
            :readonly="true"
            @click="editing = true"
            prepend-icon="mdi-api"
        />
        <dialog-form
            v-model="editing"
            :title="$localize('Integration.eboekhouden.title')"
            @commit="save"
            :allow-continue="false"
        >
            {{ $localize('Integration.eboekhouden.explainer') }}

            <v-text-field label="Username" v-model="data.username" />
            <v-text-field label="SecurityCode1" v-model="data.securityCode1" />
            <v-text-field label="SecurityCode2" v-model="data.securityCode2" />

            <template v-slot:actions-right>
                <v-btn class="mt-3 error" @click="deleteSettings">
                    Verwijderen
                </v-btn>
            </template>
        </dialog-form>
    </div>
</template>

<script>
import sendCommand from '@/services/sendCommand'
import DialogForm from '@/shared/components/DialogForm'
import { getIntegration } from '@/services/api'

export default {
    components: { DialogForm },
    props: {
        label: String,
        hint: String,
    },
    data() {
        return {
            editing: false,
            data: {
                username: '',
                securityCode1: '',
                securityCode2: '',
            },
        }
    },
    computed: {
        inputPreview() {
            if (
                this.data.username &&
                this.data.securityCode1 &&
                this.data.securityCode2
            ) {
                let preview = `Username: ${
                    this.data.username
                }, securityCode1: ${this.data.securityCode1.substring(
                    0,
                    3
                )}***, securityCode2: ${this.data.securityCode2.substring(
                    0,
                    3
                )}***`
                return preview
            } else {
                return 'Geen koppeling ingesteld'
            }
        },
    },
    async created() {
        const { result, ok } = await getIntegration.call({
            args: { provider: 'eboekhouden' },
        })

        if (ok) {
            var integration = result
            if (integration) {
                this.data.username = integration.settings.username
                this.data.securityCode1 =
                    integration.settings.securityCode1 + '***********'
                this.data.securityCode2 =
                    integration.settings.securityCode2 + '***********'
            }
        }
    },
    methods: {
        async save(callback) {
            var response = await sendCommand(
                'SaveEBoekhoudenSettings',
                this.data
            )
            callback({ success: response.success })
        },
        async deleteSettings() {
            var response = await sendCommand('DeleteEBoekhoudenSettings')
            if (response.success) {
                this.editing = false
                this.data.username = ''
                this.data.securityCode1 = ''
                this.data.securityCode2 = ''
            }
        },
    },
}
</script>

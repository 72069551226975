<template>
    <dialog-form
        :value="value"
        @input="$emit('input', $event)"
        :title="$localize('SetupMandate.title')"
        :allow-continue="false"
        @commit="save"
        :loading="loading"
        :action="dialogAction"
    >
        <template v-if="currentMandate.status !== 'notset'">
            <v-row wrap dense>
                <v-col cols="6">Naam</v-col>
                <v-col cols="6">{{ currentMandate.name }}</v-col>
                <v-col cols="6">Bank rekening</v-col>
                <v-col cols="6">{{ currentMandate.iban }}</v-col>
                <v-col cols="6">BIC/SWIFT</v-col>
                <v-col cols="6">{{ currentMandate.bic }}</v-col>
                <v-col cols="6">Datum</v-col>
                <v-col cols="6">{{
                    $format.shortDate(currentMandate.signatureDate)
                }}</v-col>
                <v-col cols="6">Status</v-col>
                <v-col cols="6" class="font-weight-bold">{{
                    currentMandate.status
                }}</v-col>
            </v-row>
        </template>
        <template v-else>
            <v-text-field
                v-model="setupMandate.name"
                :label="$localize('SetupMandate.name')"
                :rules="[validation.required]"
                autofocus
            />
            <v-text-field
                v-model="setupMandate.iban"
                :label="$localize('SetupMandate.account')"
                :hint="$localize('SetupMandate.account.hint')"
                :rules="[validation.required]"
                persistent-hint
                validate-on-blur
            />
            <v-text-field
                v-model="setupMandate.bic"
                :label="$localize('SetupMandate.bic')"
                :hint="$localize('SetupMandate.bic.hint')"
                :rules="[validation.required, validation.length(8)]"
                persistent-hint
                validate-on-blur
            />
            <div class="pt-3">
                Lees onze <a target="_new" href="https://torecs.nl/algemene-voorwaarden/">Algemene voorwaarden</a>
            </div>
            <v-switch
                :label="$localize('SetupMandate.accept')"
                :hint="$localize('SetupMandate.accept.hint')"
                :rules="[validation.true]"
                persistent-hint
            />
        </template>
        <template v-slot:actions>
            <v-btn
                :loading="saving"
                v-if="currentMandate.status !== 'notset'"
                class="error mt-3 mr-3"
                @click="deleteMandate"
                >{{ $localize('SetupMandate.delete') }}</v-btn
            >
        </template>
    </dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { getMandate } from '@/services/api'
import { required, length } from '@/services/validation'

export default {
    components: { DialogForm },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentMandate: { status: 'notset' },
            setupMandate: {
                iban: '',
                bic: '',
            },
            validation: {
                required,
                length,
                true(value) {
                    if (!value) {
                        return 'U moet akkoord gaan met onze algemene voorwaarden'
                    }
                    return true
                },
            },
            loading: false,
            saving: false,
        }
    },
    computed: {
        dialogAction() {
            if (this.currentMandate.status === 'notset') {
                return 'save'
            }
            return 'none'
        },
    },
    async created() {
        this.loading = true
        const { result, ok } = await getMandate.call()
        if (ok) {
            this.currentMandate = result
        }
        this.loading = false
    },
    methods: {
        async save(callback) {
            const result = await sendCommand('SetupMandate', this.setupMandate)

            if (result.success) {
                this.$emit('saved')
            }
            callback({ success: result.success })
        },
        async deleteMandate() {
            this.saving = true
            const result = await sendCommand('DeleteMandate')

            this.saving = false
            if (result.success) {
                this.$emit('saved')
                this.$emit('input', false)
            }
        },
    },
}
</script>

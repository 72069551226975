<template>
    <v-container fluid class="py-0 pr-0 pl-1">
        <v-row v-if="search">
            <v-col>
                <template v-for="settingCategory in visibleEditors">
                    <template v-for="setting in settingCategory">
                        <div v-show="filter(setting)" :key="setting.prop">
                            <div v-if="setting.component">
                                <component
                                    v-if="setting.prop"
                                    :is="setting.component"
                                    v-bind="setting.props"
                                    :label="
                                        $localize(
                                            'Settings.prop.' + setting.prop
                                        )
                                    "
                                    :hint="
                                        $localize(
                                            'Settings.prop.' +
                                                setting.prop +
                                                '.hint'
                                        )
                                    "
                                    v-model="settings[setting.prop]"
                                    @input="saveSetting(setting, $event)"
                                    filled
                                />
                                <component v-else :is="setting.component" />
                            </div>
                            <div v-else-if="setting.file">
                                <v-file-input
                                    :key="settings[setting.prop]"
                                    :value="
                                        settings[setting.prop]
                                            ? { name: settings[setting.prop] }
                                            : null
                                    "
                                    :prepend-icon="setting.icon"
                                    @change="saveFileSetting(setting, $event)"
                                    :label="
                                        $localize(
                                            'Settings.prop.' + setting.prop
                                        )
                                    "
                                    :hint="
                                        $localize(
                                            'Settings.prop.' +
                                                setting.prop +
                                                '.hint'
                                        )
                                    "
                                    persistent-hint
                                    :hide-details="
                                        !$localize(
                                            'Settings.prop.' +
                                                setting.prop +
                                                '.hint'
                                        )
                                    "
                                    filled
                                    :clearable="setting.allowClear"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-chip
                                                    small
                                                    label
                                                    color="primary"
                                                    v-on="on"
                                                    >{{ text }}</v-chip
                                                >
                                            </template>
                                            <v-img
                                                v-if="settings[setting.prop]"
                                                :src="
                                                    getSystemFile.create().url +
                                                    `?name=${
                                                        setting.prop
                                                    }&r=${Math.random()}`
                                                "
                                            />
                                        </v-tooltip>
                                    </template>
                                </v-file-input>
                            </div>
                            <div
                                v-else-if="typeof setting.items === 'function'"
                            >
                                <v-select
                                    @input="saveSetting(setting, $event)"
                                    filled
                                    v-model="settings[setting.prop]"
                                    :items="setting.items()"
                                    :label="
                                        $localize(
                                            'Settings.prop.' + setting.prop
                                        )
                                    "
                                    :hint="
                                        $localize(
                                            'Settings.prop.' +
                                                setting.prop +
                                                '.hint'
                                        )
                                    "
                                    persistent-hint
                                    :hide-details="
                                        !$localize(
                                            'Settings.prop.' +
                                                setting.prop +
                                                '.hint'
                                        )
                                    "
                                    :multiple="setting.multiple"
                                    :item-value="setting.itemValue"
                                    :item-text="setting.itemText"
                                    :clearable="setting.allowClear"
                                    :prepend-icon="setting.icon"
                                />
                            </div>
                            <div v-else-if="setting.type === 'textarea'">
                                <v-textarea
                                    :value="settings[setting.prop]"
                                    @change="saveSetting(setting, $event)"
                                    filled
                                    :label="
                                        $localize(
                                            'Settings.prop.' + setting.prop
                                        )
                                    "
                                    :hint="
                                        $localize(
                                            'Settings.prop.' +
                                                setting.prop +
                                                '.hint'
                                        )
                                    "
                                    persistent-hint
                                    :hide-details="
                                        !$localize(
                                            'Settings.prop.' +
                                                setting.prop +
                                                '.hint'
                                        )
                                    "
                                    :clearable="setting.allowClear"
                                    :prepend-icon="setting.icon"
                                    :rules="setting.rules || []"
                                ></v-textarea>
                            </div>
                            <div v-else-if="setting.type === 'boolean'">
                                <v-switch
                                    :value="settings[setting.prop]"
                                    @change="saveSetting(setting, $event)"
                                    :label="
                                        $localize(
                                            'Settings.prop.' + setting.prop
                                        )
                                    "
                                    :hint="
                                        $localize(
                                            'Settings.prop.' +
                                                setting.prop +
                                                '.hint'
                                        )
                                    "
                                    persistent-hint
                                    :hide-details="
                                        !$localize(
                                            'Settings.prop.' +
                                                setting.prop +
                                                '.hint'
                                        )
                                    "
                                    :prepend-icon="setting.icon"
                                    class="mb-3"
                                />
                            </div>
                            <div v-else>
                                <v-text-field
                                    :value="settings[setting.prop]"
                                    @change="saveSetting(setting, $event)"
                                    filled
                                    :label="
                                        $localize(
                                            'Settings.prop.' + setting.prop
                                        )
                                    "
                                    :hint="
                                        $localize(
                                            'Settings.prop.' +
                                                setting.prop +
                                                '.hint'
                                        )
                                    "
                                    persistent-hint
                                    :hide-details="
                                        !$localize(
                                            'Settings.prop.' +
                                                setting.prop +
                                                '.hint'
                                        )
                                    "
                                    :clearable="setting.allowClear"
                                    :prepend-icon="setting.icon"
                                    :rules="setting.rules || []"
                                />
                            </div>
                        </div>
                    </template>
                </template>
            </v-col>
        </v-row>
        <v-row v-if="!search && !$vuetify.breakpoint.mdAndUp" class="mx-4">
            <v-col>
                <v-select
                    :items="
                        Object.keys(visibleEditors).map((x) => {
                            return { key: x, editor: visibleEditors[x] }
                        })
                    "
                    :value="categoryKey"
                    @input="
                        $router.replace({
                            name: 'settingsOverviewCategory',
                            params: { category: $event },
                        })
                    "
                    outlined
                    rounded
                    hide-details
                    :item-text="(x) => $localize('Settings.tab.' + x.key)"
                    item-value="key"
                />
            </v-col>
        </v-row>
        <v-row v-if="!search">
            <v-col
                v-if="$vuetify.breakpoint.mdAndUp"
                cols="auto"
                style="width: 250px"
            >
                <v-tabs vertical :key="search">
                    <v-tab
                        v-for="(settingCategory, key) in visibleEditors"
                        :key="key"
                        :to="{
                            name: 'settingsOverviewCategory',
                            params: { category: key },
                        }"
                        :replace="true"
                        >{{ $localize('Settings.tab.' + key) }}</v-tab
                    >
                </v-tabs>
            </v-col>
            <v-col>
                <div v-for="(setting, index) in category" :key="index">
                    <div v-if="setting.component">
                        <component
                            v-if="setting.prop"
                            :is="setting.component"
                            v-bind="setting.props"
                            :label="$localize('Settings.prop.' + setting.prop)"
                            :hint="
                                $localize(
                                    'Settings.prop.' + setting.prop + '.hint'
                                )
                            "
                            v-model="settings[setting.prop]"
                            filled
                            @input="saveSetting(setting, $event)"
                        />
                        <component v-else :is="setting.component" />
                    </div>
                    <div v-else-if="setting.file" :key="settings[setting.prop]">
                        <v-file-input
                            :key="settings[setting.prop]"
                            :value="
                                settings[setting.prop]
                                    ? { name: settings[setting.prop] }
                                    : null
                            "
                            :prepend-icon="setting.icon"
                            @change="saveFileSetting(setting, $event)"
                            :label="$localize('Settings.prop.' + setting.prop)"
                            :hint="
                                $localize(
                                    'Settings.prop.' + setting.prop + '.hint'
                                )
                            "
                            persistent-hint
                            :hide-details="
                                !$localize(
                                    'Settings.prop.' + setting.prop + '.hint'
                                )
                            "
                            filled
                            :clearable="setting.allowClear"
                        >
                            <template v-slot:selection="{ text }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                            v-on="on"
                                            >{{ text }}</v-chip
                                        >
                                    </template>
                                    <v-img
                                        v-if="settings[setting.prop]"
                                        :src="
                                            getSystemFile.create().url +
                                            `?name=${
                                                setting.prop
                                            }&r=${Math.random()}`
                                        "
                                    />
                                </v-tooltip>
                            </template>
                        </v-file-input>
                    </div>
                    <div v-else-if="typeof setting.items === 'function'">
                        <v-select
                            @input="saveSetting(setting, $event)"
                            filled
                            v-model="settings[setting.prop]"
                            :items="setting.items()"
                            :label="$localize('Settings.prop.' + setting.prop)"
                            :hint="
                                $localize(
                                    'Settings.prop.' + setting.prop + '.hint'
                                )
                            "
                            persistent-hint
                            :hide-details="
                                !$localize(
                                    'Settings.prop.' + setting.prop + '.hint'
                                )
                            "
                            :multiple="setting.multiple"
                            :item-value="setting.itemValue"
                            :item-text="setting.itemText"
                            :clearable="setting.allowClear"
                            :prepend-icon="setting.icon"
                        />
                    </div>
                    <div v-else-if="setting.type === 'textarea'">
                        <v-textarea
                            :value="settings[setting.prop]"
                            @change="saveSetting(setting, $event)"
                            filled
                            :label="$localize('Settings.prop.' + setting.prop)"
                            :hint="
                                $localize(
                                    'Settings.prop.' + setting.prop + '.hint'
                                )
                            "
                            persistent-hint
                            :hide-details="
                                !$localize(
                                    'Settings.prop.' + setting.prop + '.hint'
                                )
                            "
                            :clearable="setting.allowClear"
                            :prepend-icon="setting.icon"
                            :rules="setting.rules || []"
                        ></v-textarea>
                    </div>
                    <div v-else-if="setting.type === 'boolean'">
                        <v-switch
                            :value="settings[setting.prop]"
                            @change="saveSetting(setting, $event)"
                            :label="$localize('Settings.prop.' + setting.prop)"
                            :hint="
                                $localize(
                                    'Settings.prop.' + setting.prop + '.hint'
                                )
                            "
                            persistent-hint
                            :hide-details="
                                !$localize(
                                    'Settings.prop.' + setting.prop + '.hint'
                                )
                            "
                            :prepend-icon="setting.icon"
                            class="mb-3"
                        />
                    </div>
                    <div v-else>
                        <v-text-field
                            :value="settings[setting.prop]"
                            @change="saveSetting(setting, $event)"
                            filled
                            :label="$localize('Settings.prop.' + setting.prop)"
                            :hint="
                                $localize(
                                    'Settings.prop.' + setting.prop + '.hint'
                                )
                            "
                            persistent-hint
                            :hide-details="
                                !$localize(
                                    'Settings.prop.' + setting.prop + '.hint'
                                )
                            "
                            :type="setting.type || 'text'"
                            :clearable="setting.allowClear"
                            :prepend-icon="setting.icon"
                            :rules="setting.rules || []"
                        />
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BankInfoInputField from './dialogs/BankInfoInputField'
import CsvSettings from './dialogs/CsvSettings'
import Vat from './dialogs/Vat'
import {
    getEnabledProjectStatusesByPhase,
    getSystemSettings,
    getLanguages,
    getSystemFile,
} from '@/services/api'
import sendCommand from '@/services/sendCommand'
import ProjectStatusOverview from '@/main/projects/ProjectStatusOverview'
import RelationGroupsOverview from '@/main/relations/RelationGroupsOverview'
import UserOverview from '@/main/users/UserOverview'
import DashboardsOverview from './DashboardsOverview'
import SmtpSettings from './SmtpSettings'
import IntegrationSettings from './IntegrationSettings'
import Permissions from './Permissions'
import AccountAdministration from './AccountAdministration'
import CalculationsOverview from './CalculationsOverview'
import EmailInputField from '@/main/components/forms/EmailInputField'
import SelectRelationGroup from '@/main/components/forms/SelectRelationGroup'
import InputColor from '@/main/components/forms/InputColor'
import { currentSession } from '@/services/session'
import PubSub from 'pubsub-js'
import timezones from 'timezones-list'

export default {
    data() {
        return {
            getSystemFile: getSystemFile,
            search: '',
            t: timezones,
            defaultCategory: null,
            settings: {
                systemLanguage: 'Engels',
                productionDays: [],
                invoiceBankLabels: [],
                invoiceBankTexts: [],
                invoiceCompanyLabels: [],
                invoiceCompanyTexts: [],
            },
            editors: {
                system: [
                    {
                        prop: 'language',
                        items: () => this.languages,
                        refresh: true,
                        icon: 'mdi-translate',
                    },
                    {
                        prop: 'timezone',
                        items: () => timezones,
                        itemValue: 'tzCode',
                        itemText: 'label',
                        icon: 'mdi-map-clock',
                    },
                    {
                        prop: 'calendarStartTime',
                        type: 'number',
                        icon: 'mdi-clock-start',
                    },
                    {
                        prop: 'calendarEndTime',
                        type: 'number',
                        icon: 'mdi-clock-end',
                    },
                    { prop: 'csv', component: CsvSettings },
                ],
                users: [{ component: UserOverview }],
                projects: [
                    {
                        prop: 'initialDeliveryMethod',
                        items: () => this.deliveryMethods,
                        allowClear: true,
                        icon: 'mdi-truck',
                    },
                    {
                        prop: 'initialRequestStatus',
                        items: () => this.requestStatuses,
                        itemText: 'name',
                        itemValue: 'projectStatusId',
                        icon: 'mdi-format-list-bulleted',
                    },
                    {
                        prop: 'initialOfferStatus',
                        items: () => this.offerStatuses,
                        itemText: 'name',
                        itemValue: 'projectStatusId',
                        icon: 'mdi-format-list-bulleted',
                    },
                    {
                        prop: 'initialOrderStatus',
                        items: () => this.orderStatuses,
                        itemText: 'name',
                        itemValue: 'projectStatusId',
                        icon: 'mdi-format-list-bulleted',
                    },
                    {
                        prop: 'productionLeadTime',
                        type: 'number',
                        icon: 'mdi-clock',
                    },
                    //  {prop:'productionDays',label: 'Productiedagen', hint: 'Dagen die meetellen in de productie doorlooptijd', items: () => this.days, multiple: true},
                    {
                        prop: 'offerValidityDays',
                        type: 'number',
                        icon: 'mdi-calendar',
                    },
                    {
                        prop: 'vat',
                        component: Vat,
                    },
                    {
                        prop: 'invoicePaymentTerm',
                        type: 'number',
                        icon: 'mdi-numeric',
                    },
                    {
                        prop: 'invoiceMinimumNumber',
                        type: 'number',
                        icon: 'mdi-numeric',
                    },
                    //  {prop:'vatPercentage',label: 'BTW percentage', type: 'number'},
                    {
                        prop: 'defaultRelationGroup',
                        component: SelectRelationGroup,
                    },
                ],
                projectStatusses: [{ component: ProjectStatusOverview }],
                dashboards: [{ component: DashboardsOverview }],
                relationGroups: [{ component: RelationGroupsOverview }],
                calculations: [{ component: CalculationsOverview }],
                /*display: [
          //  {prop:'dateFormat',label: 'Datum notatie', hint: 'Bepaald hoe datum worden weergeven', items: () => this.dateFormats, itemText: 'text', itemVaue: 'value'},
          //  {prop:'currencyFormat',label: 'Valuta notatie', hint: 'Bepaald hoe valuta worden weergeven', items: () => this.currencyFormats, itemText: 'text', itemVaue: 'value'},
          //  {prop:'percentageFormat',label: 'Percentage notatie', hint: 'Bepaald hoe percentages worden weergeven', items: () => this.percentageFormats, itemText: 'text', itemVaue: 'value'},
          // {prop:'numberFormat',label: 'Nummer notatie', hint: 'Bepaald hoe nummers worden weergeven', items: () => this.numberFormats, itemText: 'text', itemVaue: 'value'},
        ],*/
                company: [
                    { prop: 'companyName', icon: 'mdi-office-building' },
                    { prop: 'companyWebsite', icon: 'mdi-web' },
                    { prop: 'companyEmail', icon: 'mdi-at' },
                    { prop: 'companyPhone', icon: 'mdi-phone' },
                    { prop: 'companyFax', icon: 'mdi-fax' },
                    { prop: 'companyStreet', icon: 'mdi-map' },
                    { prop: 'companyZipCode', icon: 'mdi-compass' },
                    { prop: 'companyCity', icon: 'mdi-city' },
                    { prop: 'companyLogo', file: true, icon: 'mdi-image' },
                    { prop: 'companyPrimaryColor', component: InputColor },
                    { prop: 'companyContrastColor', component: InputColor },
                    {
                        prop: 'companyLayout',
                        items: () => this.layouts,
                        icon: 'mdi-translate',
                    },
                    { prop: 'companyBankInfo', component: BankInfoInputField },
                    { prop: 'companyTaxNumber', icon: 'mdi-bank' },
                    { prop: 'companyCocNumber', icon: 'mdi-bank' },
                    { prop: 'layoutShowUserName', type: 'boolean', icon: 'mdi-translate'  },
                    { prop: 'layoutHideProductLine', type: 'boolean', icon: 'mdi-translate'  },
                    { prop: 'layoutHidePaymentInfoOnOffer', type: 'boolean', icon: 'mdi-translate'  },
                ],
                texts: [
                    { prop: 'offerStartText', type: 'textarea' },
                    { prop: 'offerEndText', type: 'textarea' },
                    { prop: 'invoiceStartText', type: 'textarea' },
                    { prop: 'invoiceEndText', type: 'textarea' },
                    { prop: 'cashReceiptStartText', type: 'textarea' },
                    { prop: 'cashReceiptEndText', type: 'textarea' },
                    { prop: 'orderConfirmationStartText', type: 'textarea' },
                    { prop: 'orderConfirmationEndText', type: 'textarea' },
                    { prop: 'invoiceOverviewStartText', type: 'textarea' },
                    { prop: 'invoiceOverviewEndText', type: 'textarea' },
                ],
                emails: [
                    { prop: 'emailOffer', component: EmailInputField },
                    {
                        prop: 'emailOrderConfirmation',
                        component: EmailInputField,
                    },
                    { prop: 'emailStatusUpdate', component: EmailInputField },
                    { prop: 'emailInvoice', component: EmailInputField },
                    {
                        prop: 'emailInvoiceOverview',
                        component: EmailInputField,
                    },
                    { prop: 'emailFooterText', type: 'textarea' },
                ],
                smtp: [{ component: SmtpSettings }],
                integration: [{ component: IntegrationSettings }],
                administration: [{ component: AccountAdministration }],
                permissions: [{ component: Permissions }],
            },
            days: [
                { value: 0, text: 'Maandag' },
                { value: 1, text: 'Dinsdag' },
                { value: 2, text: 'Woensdag' },
                { value: 3, text: 'Donderdag' },
                { value: 4, text: 'Vrijdag' },
                { value: 5, text: 'Zaterdag' },
                { value: 6, text: 'Zondag' },
            ],
            deliveryMethods: [
                { value: 'pickup', text: 'Afhalen' },
                { value: 'delivery', text: 'Versturen' },
                { value: 'montage', text: 'Monteren' },
                { value: 'notApplicable', text: 'N.v.t.' },
            ],
            layouts:['default', 'kaders'],
            languages: [],
            requestStatuses: [],
            offerStatuses: [],
            orderStatuses: [],
            dateFormats: [
                { text: '2001-01-14', value: 'yyyy-MM-dd' },
                { text: '12/01/2001', value: 'dd/MM/yyyy' },
            ],
            currencyFormats: [{ text: '€ 12.345,67', value: '' }],
            percentageFormats: [{ text: '12345.67 %', value: '' }],
            numberFormats: [{ text: '12345.67', value: '' }],
        }
    },
    computed: {
        categoryKey() {
            const cat = this.$route.params.category || this.defaultCategory
            return cat
        },
        category() {
            return this.editors[this.categoryKey]
        },
        visibleEditors() {
            var editors = Object.keys(this.editors).reduce((result, key) => {
                switch (key) {
                    case 'users':
                        if (!this.$session.roles.includes('settings_users')) {
                            return result
                        }
                        break
                    case 'system':
                    case 'projects':
                    case 'relationGroups':
                    case 'calculations':
                    case 'company':
                    case 'texts':
                    case 'emails':
                    case 'dashboards':
                    case 'projectStatusses':
                        if (!this.$session.roles.includes('settings_general')) {
                            return result
                        }
                        break
                    case 'permissions':
                        if (!this.$session.roles.includes('settings_rights')) {
                            return result
                        }
                        break
                    case 'smtp':
                        if (!this.$session.roles.includes('settings_smtp')) {
                            return result
                        }
                        break
                    case 'integration':
                        if (
                            !this.$session.roles.includes(
                                'settings_integration'
                            )
                        ) {
                            return result
                        }
                        break
                }
                result[key] = this.editors[key]
                return result
            }, {})
            return editors
        },
    },
    async created() {
        this.unsubscribe = PubSub.subscribe('search', (message, data) => {
            if (data.mode === 'page') {
                this.search = data.keyword
            } else {
                this.search = ''
            }
        })
        await this.loadSystemSettings()
        await this.loadLanguages()
        if (this.$session.roles.includes('settings_general')) {
            this.requestStatuses = await this.loadEnabledProjectStatusesByPhase(
                'request'
            )
            this.offerStatuses = await this.loadEnabledProjectStatusesByPhase(
                'offer'
            )
            this.orderStatuses = await this.loadEnabledProjectStatusesByPhase(
                'order'
            )
        }
        const visibleEditorKeys = Object.keys(this.visibleEditors)
        if (visibleEditorKeys.length > 0) {
            this.defaultCategory = visibleEditorKeys[0]
        } else {
            this.defaultCategory = null
        }
    },
    methods: {
        async loadEnabledProjectStatusesByPhase(phase) {
            const { result, ok } = await getEnabledProjectStatusesByPhase.call({
                args: { phase: phase },
            })
            if (ok) {
                return result
            }
            return []
        },
        async loadLanguages() {
            const { result, ok } = await getLanguages.call()
            if (ok) {
                this.languages = result
            }
        },
        async loadSystemSettings() {
            const { result, ok } = await getSystemSettings.call()
            if (ok) {
                this.settings = result.system
            }
        },
        async refreshSettings() {
            const result = await currentSession(true)
            this.settings = result.settings
        },
        async saveFileSetting(setting, value) {
            const formData = new FormData()
            if (value) {
                formData.append(value.name, value)
            }
            formData.append(
                'command',
                JSON.stringify({ key: setting.prop, file: true })
            )

            const result = await sendCommand('SaveSetting', formData, {
                'Content-Type': 'multipart/form-data',
            })
            if (!result.success) return

            if (value) {
                this.settings[setting.prop] = value.name
            } else {
                this.settings[setting.prop] = null
            }
            if (setting.refresh) {
                window.location.reload()
            } else {
                await this.refreshSettings()
            }
        },
        async saveSetting(setting, value) {
            const result = await sendCommand('SaveSetting', {
                key: setting.prop,
                value: value + '',
            })
            if (result.success) {
                if (setting.refresh) {
                    window.location.reload()
                } else {
                    await this.refreshSettings()
                }
            }
        },
        filter(setting) {
            if (!setting.prop) {
                return false
            }
            if (typeof setting.items === 'function') {
                return this.match([
                    ...setting.items(),
                    this.$localize('Settings.prop.' + setting.prop),
                    this.$localize('Settings.prop.' + setting.prop + '.hint'),
                    setting.prop,
                ])
            }
            return this.match([
                this.$localize('Settings.prop.' + setting.prop),
                this.$localize('Settings.prop.' + setting.prop + '.hint'),
                setting.prop,
            ])
        },
        match(data) {
            if (!this.search) {
                return true
            }
            if (!data) {
                return false
            }

            if (typeof data === 'string') {
                return data.indexOf(this.search) >= 0
            }
            for (let index = 0; index < data.length; index++) {
                const element = data[index]
                if (
                    typeof element === 'string' &&
                    element.toLowerCase().indexOf(this.search.toLowerCase()) >=
                        0
                ) {
                    return true
                }
            }
            return false
        },
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.unsubscribe)
    },
}
</script>

<template>
    <dialog-form
        :value="value"
        @input="$emit('input', $event)"
        @commit="save"
        :title="$localize('AddLicense.title')"
        :allow-continue="false"
    >
        <v-select
            v-model="changeLicense.maxProjects"
            :items="tiers"
            :item-text="itemText"
            hint="Kies het aantal projecten dat u denkt nodig te hebben per maand."
            persistent-hint
            item-value="maxProjects"
            label="Licentie"
        >
            <template v-slot:item="{ item }">
                {{ itemText(item) }}
                <span v-if="item.disabled"> &nbsp;(huidige) </span>
            </template>
        </v-select>
        <div class="mt-3">
            Lees onze
            <a target="_new" href="https://torecs.nl/algemene-voorwaarden/"
                >Algemene voorwaarden</a
            >
        </div>
        <v-switch
            :label="$localize('SetupMandate.accept')"
            :hint="$localize('SetupMandate.accept.hint')"
            :rules="[validation.true]"
            persistent-hint
        />
        <v-alert
            v-if="
                changeLicense.maxProjects !== null &&
                changeLicense.start === 'now'
            "
            class="mt-3"
            type="info"
        >
            De gekozen licentie zal direct ingaan. Aan het einde van deze maand
            zal de factuur
            {{ $format.currency(extraCosts) }} bedragen. Daarna betaald u het
            bovenstaande bedrag per maand.
        </v-alert>
    </dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { getDaysInMonth } from 'date-fns'

export default {
    components: { DialogForm },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tiers: [],
            changeLicense: {
                maxProjects: 100,
                start: 'now',
            },
            validation: {
                true(value) {
                    if (!value) {
                        return 'U moet akkoord gaan met onze algemene voorwaarden'
                    }
                    return true
                },
            },
        }
    },
    computed: {
        extraCosts() {
            const daysInMonth = getDaysInMonth(new Date())
            let daysLeftInMonth = daysInMonth - new Date().getDate()

            let oldCost = 0
            let newCost = (this.changeLicense.maxProjects / 100) * 20 + 10
            let costIncrease = newCost - oldCost
            let costIncreasePerDay = costIncrease / daysInMonth
            return costIncreasePerDay * daysLeftInMonth
        },
    },
    created() {
        for (let i = 1; i < 10; i++) {
            const amount = 10 + i * 20
            this.tiers.push({
                amount: amount,
                maxProjects: i * 100,
            })
        }
    },
    methods: {
        async save(callback) {
            const result = await sendCommand(
                'ChangeLicense',
                this.changeLicense
            )

            if (result.success) {
                this.$emit('saved')
            }

            callback({ success: result.success })
        },
        itemText(item) {
            return (
                item.maxProjects +
                ' Projecten per maand (' +
                this.$format.currency(item.amount) +
                ')'
            )
        },
    },
}
</script>

import { getTranslation, getTranslations } from '@/services/api'
import {currentSession} from '@/services/session'

import Vue from 'vue'

import { formatShortTime, formatShortDate, formatCurrency, formatShortDateTime, formatTimespan, year, formatDateTime, formatBytes, formatBytesShort } from "@/services/formatters";

let translations;
let language = 'nl-NL';
let session;

const $settings = {};
const $session = {};

const Mountain = {
  localize: localize,
  install(Vue) {

    Vue.prototype.$settings = $settings;
    Vue.prototype.$session = $session;
    Vue.prototype.$localize = localize;

    Vue.prototype.$format = {
      shortTime: formatShortTime,
      shortDate: formatShortDate,
      shortDateTime: formatShortDateTime,
      dateTime: formatDateTime,
      currency: formatCurrency,
      year: year,
      timespan: formatTimespan,
      bytes: formatBytes,
      bytesShort: formatBytesShort
    }
  }
}

export default Mountain;

export function localize (key, args) {
  if(!key) return ''
  
  if (translations) {
    const translation = translations[key]
    if (translation || translation === '') {

      let formattedTranslation = translation;
      if(args){
        var properties = Object.getOwnPropertyNames(args);
        for(var index in properties){
          formattedTranslation = formattedTranslation.replace('{' + properties[index] + '}', args[properties[index]])
        }
      }
      return formattedTranslation;
    }
    else {
      if(translation === undefined){
        getTranslation.create({ language: language, key: key }).load()
      }
      return key;
    }
  }
  return '';
}

export async function reload(){
  session = await currentSession();
  language = session.settings.language || 'nl-NL';

  if(!translations) {
    translations = await getTranslations.create({ language: language }).load()
  }
  Vue.set($session, 'isLoggedIn', session.isLoggedIn);
  Vue.set($session, 'userId', session.userId);
  Vue.set($session, 'login', session.login);
  Vue.set($session, 'email', session.email);
  Vue.set($session, 'isAdministrator', session.isAdministrator);
  Vue.set($session, 'isTenant', session.isTenant);
  Vue.set($session, 'impersonated', session.impersonated);
  Vue.set($session, 'domain', session.domain);
  Vue.set($session, 'roles', session.roles);
  Vue.set($session, 'licenseStatus', session.licenseStatus);
  Vue.set($session, 'licensePaymentStatus', session.licensePaymentStatus);

  for (const property in session.settings) {
    Vue.set($settings, property, session.settings[property]);
  }
  for (const property in session.userSettings) {
    Vue.set($settings, property, session.userSettings[property]);
  }
}

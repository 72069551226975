import axios from 'axios'

var axiosConfig = {
    withCredentials: true,
    timeout: 30000,
    validateStatus: function (status) {
        return status < 500 // Reject only if the status code is greater than or equal to 500
    },
}

const verbose = false
const port = process.env.VUE_APP_API_PORT
let portPart = ':' + window.location.port
if (port) {
    portPart = ':' + port
}

const host = `${window.location.protocol}//${window.location.hostname}${portPart}`

let globalHeaders = {}

export const apiRoot = host + '/api/'

import { showSnackbar, showError } from '@/services/snackbars'

export function addGlobalHeader(key, value) {
    globalHeaders[key] = value
}

export function appendOptions(url, options) {
    options = options || {}
    if (verbose) {
        //console.log(`Append option to url: ${url}`)
        //console.log(`-> : ${JSON.stringify(options)}`)
    }

    if (!options) {
        return url
    }

    var urlWithOptions = url
    var amp = false

    var entries = Object.entries(options)
    for (var key in entries) {
        var entry = entries[key]
        if (entry[1] !== null && entry[1] !== undefined) {
            urlWithOptions +=
                (amp ? '&' : '?') +
                encodeURIComponent(entry[0]) +
                '=' +
                encodeURIComponent(entry[1])
            amp = true
        }
    }
    if (verbose) {
        //console.log(`<- : ${urlWithOptions}`)
    }
    return urlWithOptions
}

export class ApiEndPoint {
    constructor(urlPattern, maxAge, method) {
        this.urlPattern = urlPattern
        this.calls = {}
        this.lastCall = null
        this.maxAge = maxAge || 10
        this.method = method || 'GET'
    }

    /**
     * Creates a new ApiCall for the ApiEndPoint. If this exact api call was created before, that instead is returned instead.
     * @param {Object} settings A settings object which contains argument used to create the api call, These arguments are formatted into the url pattern.
     * @return {ApiCall} An ApiCall object
     */
    create(settings) {
        if (verbose) {
            //console.log(`Creating ApiCall for ApiEndPoint: ${this.urlPattern}`)
            //console.log(`-> : ${JSON.stringify(settings)}`)
        }

        settings = settings || {}
        const usedSettings = []
        const pattern = /\{(\w+)\}/gim
        const fullUrl = this.urlPattern.replace(pattern, function (a, key) {
            if (
                !Object.prototype.hasOwnProperty.call(settings, key) ||
                settings[key] === undefined
            ) {
                console.error(`Undefined variable '${key}' in settings object`)
            }
            usedSettings.push(key)
            return settings[key]
        })
        var keys = Object.keys(settings)
        for (const keyIndex in keys) {
            const key = keys[keyIndex]
            if (usedSettings.indexOf(key) === -1) {
                console.error(`${key} was not found in url pattern`)
            }
        }
        if (this.calls[fullUrl] === undefined) {
            this.calls[fullUrl] = new ApiCall(
                apiRoot + fullUrl,
                fullUrl,
                this.maxAge,
                this.method
            )
        } else {
            if (verbose) {
                //console.log(`Getting call from cache`)
            }
        }
        const call = this.calls[fullUrl]
        this.lastCall = call
        if (verbose) {
            //console.log(`<- : ${call.url}`)
        }
        return call
    }

    async call({ args, q, payload } = {}) {
        var response = await this.create(args)
            .call(q, payload)
            .then((response) => {
                return { result: response.data, ok: true }
            })
            .catch((response) => {
                return { err: response.data.message, ok: false }
            })

        return response
    }
}

class ApiCall {
    constructor(url, key, maxAge, method) {
        this.url = url
        this.key = key
        this.listeners = []
        this.handlers = {
            invalidated: [],
            loaded: [],
        }
        this.maxAge = maxAge
        this.currentCall = null
        this.cleanCurrentCallTimeout = null
        this.method = method
    }

    _checkEventType(eventType) {
        let handlers = this.handlers[eventType]
        if (handlers === undefined) {
            //console.error(`Unknown event type: ${eventType}`)
        }
    }

    /**
     * Emits an event to all registered subscribed
     * @param {String} eventType The type of event to subscribe to. Possible values: 'invalidated', 'loaded'.
     * @param {Object} event The object pass to each callback handler.
     */
    emit(eventType, event) {
        this._checkEventType(eventType)

        let listeners = this.handlers[eventType]
        if (listeners) {
            for (let index = 0; index < listeners.length; index++) {
                let callback = listeners[index]
                callback(event)
            }
        }
    }

    /**
     * Subscribes to an event
     * @param {String} eventType The type of event to subscribe to. Possible values: 'invalidated', 'loaded'.
     * @param {Function} callback The the callback function which is invoked when the event occurs.
     */
    subscribe(eventType, callback) {
        this._checkEventType(eventType)

        this.handlers[eventType].push(callback)
    }

    /**
     * Unsubscribes to an event. Does nothing if not already subscribed.
     * @param {String} eventType The type of event to unsubscribe to. Possible values: 'invalidated', 'loaded'.
     * @param {Function} callback The callback to unsubscribe
     */
    unsubscribe(eventType, callback) {
        this._checkEventType(eventType)

        const index = this.handlers[eventType].indexOf(callback)
        if (index >= 0) {
            this.handlers[eventType].splice(index, 1)
        }
    }

    /**
     * Loads this api call from either cache or from the backend.
     * @param {Object} options The options to use in the api call.
     * @return {promise} Returns a promise that, when resolved passes the return data of api call.
     */
    async load(options, payload) {
        const response = await this.call(options, payload)
        return response.data
        /*
    return await this.call(options);

    if(this.currentCall === null){
      this.currentCall = this.call(options);
    }
    
    const call = this.currentCall;
    
    const response = await call
    
    if(this.cleanCurrentCallTimeout === null){
      this.cleanCurrentCallTimeout = setTimeout(() =>{
        this.currentCall = null;
        this.cleanCurrentCallTimeout = null;
      }, 1000);
    }

    return response.data*/
    }

    /**
     * Executes this call using the specified options.
     */
    call(options, payload) {
        // const fullUrl = appendOptions(this.url, options)
        const maxTries = 2
        var promise = new Promise(async (resolve, reject) => {
            let result
            for (let t = 0; t < maxTries; t++) {
                result = await this._tryCall(options, payload)
                if (result.success) {
                    if (result.response.status < 400) {
                        // resolve
                        resolve(result.response)
                        if (this.listeners.length > 0) {
                            for (
                                let index = 0;
                                index < this.listeners.length;
                                index++
                            ) {
                                let callback = this.listeners[index]

                                callback(result.response.data)
                            }
                        }
                    } else {
                        showSnackbar({
                            text: result.response.data.message,
                            color: 'error',
                        })
                        reject(result.response)
                    }
                    return
                } else {
                    const error = result.response
                    if (error.code === 'ERR_CANCELED') {
                        return
                    }
                    console.log(error)
                    if (error.response) {
                        // Response was received, show error and reject
                        if (error.response.status >= 500) {
                            showError({
                                data: error.response.data,
                                status: error.status,
                                statusText: error.statusText,
                            })
                        } else {
                            showSnackbar({
                                text: error.message,
                                color: 'error',
                                timeout: 0,
                            })
                        }
                        reject(error)
                        return
                    } else if (error.request) {
                        // Request was made, but no response, try again
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        showSnackbar({
                            text: error.message,
                            color: 'error',
                            timeout: 0,
                        })
                        reject(error)
                        return
                    }
                }
            }
            reject(result.response)
        })

        return promise
    }

    async _tryCall(options, payload) {
        let fullUrl = this.url
        if (typeof options == 'string') {
            fullUrl = this.url + options
        } else {
            fullUrl = appendOptions(this.url, options)
        }
        let success = false
        let returnValue = null

        this.controller = new AbortController()
        await axios({
            ...axiosConfig,
            method: this.method,
            url: fullUrl,
            data: payload,
            headers: globalHeaders,
            signal: this.controller.signal,
        })
            .then((response) => {
                returnValue = response
                success = true
            })
            .catch((error) => {
                returnValue = error
            })
        return {
            response: returnValue,
            success: success,
        }
    }
}
